export default [
  {
    title: 'VMware Workstation 15',
    description: 'Version complète pour Windows',
    licensekey: 'H40C3-QZ3EQ-K87DA-0A80H-AT8HH',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/workstation15.png',
    urlUI: 'Workstation Pro',
    url: 'https://www.vmware.com/fr/products/workstation-pro/workstation-pro-evaluation.html'
  },
  {
    title: 'VMware Workstation 15',
    description: 'Version complète pour Linux 64 bits',
    licensekey: 'M402J-QZJD5-28GDC-0A0HK-AM0HM',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/workstation15.png',
    urlUI: 'Workstation Pro',
    url: 'https://www.vmware.com/fr/products/workstation-pro/workstation-pro-evaluation.html'
  },
  {
    title: 'VMware Workstation 16',
    description: 'Version complète pour Windows',
    licensekey: 'N02A2-NGJ01-18CJ3-0UAHH-2XRHM',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/workstation16.png',
    urlUI: 'Workstation Pro',
    url: 'https://www.vmware.com/fr/products/workstation-pro/workstation-pro-evaluation.html'
  },
  {
    title: 'VMware Workstation 16',
    description: 'Version complète pour Linux 64 bits',
    licensekey: 'N42A2-NGL03-N82JC-0R2AP-C10KH',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/workstation16.png',
    urlUI: 'Workstation Pro',
    url: 'https://www.vmware.com/fr/products/workstation-pro/workstation-pro-evaluation.html'
  },
  {
    title: 'VMware Fusion 11',
    description: 'Version complète pour Mac OS X',
    licensekey: '1M0A3-VYL00-P8AD2-09822-A104M',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/fusion11.png',
    urlUI: 'Fusion',
    url: 'https://my.vmware.com/web/vmware/info?slug=desktop_end_user_computing/vmware_fusion/11_0'
  },
  {
    title: 'VMware Fusion 12',
    description: 'Version complète pour Mac OS X',
    licensekey: 'NM6CK-JF38H-J82JA-083R0-30UNH',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/fusion12.png',
    urlUI: 'Fusion',
    url: 'https://my.vmware.com/web/vmware/info?slug=desktop_end_user_computing/vmware_fusion/12_0'
  },
  {
    title: 'VMware vSphere 6.x Entrepise',
    description: 'vSphere ESX(i) 6.x',
    licensekey: '1102Q-NCH5J-E8A94-029KH-0MV2P',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/vsphere.png',
    urlUI: 'vSphere',
    url: 'https://www.vmware.com/go/download-vsphere-fr'
  },
  {
    title: 'VMware vCenter Server 6.x',
    description: 'vCenter Server Appliance 6.x',
    licensekey: '1H61H-XCK44-98P9C-0A3R2-00WKJ',
    enddate: '31/11/2020',
    imageUrl: '/images/vmware/vcenter.png',
    urlUI: 'vCenter (nécessite un compte vmWare)',
    url: 'https://www.vmware.com/go/download-vsphere'
  },
  {
    title: 'VMware vSphere 7.x Enterprise',
    description: 'vSphere ESX(i) 7.x',
    licensekey: '5M03J-N92EQ-N8TGD-0CARH-11MKN',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/vsphere.png',
    urlUI: 'vSphere',
    url: 'https://www.vmware.com/go/download-vsphere-fr'
  },
  {
    title: 'VMware vCenter Server 7.x',
    description: 'vCenter Server Appliance 7.x',
    licensekey: '4543J-JT1DN-N8JG1-05286-A867N',
    enddate: '01/11/2021',
    imageUrl: '/images/vmware/vcenter.png',
    urlUI: 'vCenter (nécessite un compte vmWare)',
    url: 'https://www.vmware.com/go/download-vsphere'
  },
];
