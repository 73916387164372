import BaseAPI from "./BaseAPI";

export default class WebStorageAPI extends BaseAPI {

  constructor() {
    super('https://storage.ws.montpellier.epsi.fr/api/folders');
  }

  /*
   Obtention des infos de stockage
   *
   */
  getStorage() {
    return super.apiGetAll();
  }

  getStorageOfUser(login) {
    return super.apiGet(login);
  }

}
